import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { addEvent, Events } from '../../services/gtag';

interface Props {
  text: string;
  className?: string;
  event?: Events;
}

export default function LoginButton(props: Props) {
  const router = useRouter();
  useEffect(() => {
    router.prefetch('/install');
  }, [router]);

  {
    /* eslint-disable-next-line @next/next/no-html-link-for-pages */
  }
  return (
    // eslint-disable-next-line @next/next/no-html-link-for-pages
    <a
      className={props.className}
      href='/api/auth/login'
      onClick={() => addEvent(props.event ? props.event : 'login')}
    >
      <div>

      {props.text}
      </div>
    </a>
  );
}