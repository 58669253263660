import { Dispatch, SetStateAction, useCallback, useState, useEffect } from 'react';

const usePersistentStorageState = (
  key: string,
  initialValue?: string,
): [string | undefined, (Dispatch<SetStateAction<string>>), () => void] => {
  const [state, setState] = useState<string | undefined>(initialValue);

  useEffect(() => {
    const val = localStorage.getItem(key);
    if (val != null) {
      setState(val);
    }
  }, [setState, key]);

  const set: Dispatch<SetStateAction<string>> = useCallback(
    (val) => {
      localStorage.setItem(key, val as string);
      setState(val as string);
    },
    [setState, key],
  );

  const remove = useCallback(() => {
    localStorage.removeItem(key);
    setState(undefined);
  }, [key, setState]);

  return [state, set, remove];
};

export default usePersistentStorageState;
