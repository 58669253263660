import clsx from 'clsx';
import React from 'react';
import LoginButton from '../buttons/LoginButon';

export type CTAProps = {
  motivationText?: string;
  buttonText?: string;
};

export default function CTA(cta: CTAProps) {
  const {
    motivationText = 'Looking to Reduce Time Spent on Documentation and Administrative Tasks?',
    buttonText = 'Get Started',
  } = cta;
  return (
    <div
      className={clsx(
        'flex flex-col',
        'pt-[3.125rem] pb-[4.375rem] desktop:py-[4.5rem] px-8 gap-[1.313rem] desktop:gap-6 justify-center items-center',
      )}
    >
      <div
        className="text-white text-tali-h2-mobile not-italic font-noto-serif text-left desktop:text-center w-[19.438rem] desktop:w-auto"
        dangerouslySetInnerHTML={{ __html: motivationText }}
      />
      <LoginButton
        text={buttonText}
        className={clsx(
          'py-5 px-6 desktop:px-12 w-[19.438rem] desktop:w-auto',
          'text-tali-black not-italic text-tali-btn text-center font-inter',
          'yellow-btn',
        )}
        event="footerRegisterButton"
      />
    </div>
  );
}
