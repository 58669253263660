import Link from 'next/link';
import { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';

import styles from './NotificationBanner.module.scss';
import usePersistentStorageState from '~/hooks/usePersistantStorage';


const hideOnPages = ['/install'];

const NotificationBanner = () => {
  const [hideBanner, setHideBanner] = usePersistentStorageState('taliBannerHidden', 'false');

  useEffect(() => {
    if (hideOnPages.includes(window.location.pathname)) {
      setHideBanner('true');
    }
  }, [setHideBanner]);

  if (hideBanner === 'true') {
    return <></>;
  }

  return (
    <div className={styles.notificationBanner}>
      Tali Desktop App is now available for Windows!
      <Link href="/install">
        <a>Download</a>
      </Link>
      <button className={styles.closeBanner} onClick={() => setHideBanner('true')}><CloseIcon /></button>
    </div>
   );
};

export default NotificationBanner;