import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';

// Images
import SmallTaliIcon from '../../public/images/downloads/small-tali-logo.svg';
import Twitter from '../../public/images/downloads/twitter-icon.svg';
import LinkedinIcon from '../../public/images/downloads/linkedin-icon.svg';

// Components
import Text from '../display/Text';

// Stylesheets
import styles from './Footer.module.scss';
import CTA, { CTAProps } from './CTA';

type FooterProps = { cta?: CTAProps | boolean };

export default function Footer(footer: FooterProps) {
  const { cta = true } = footer;
  const disableCTA = cta ? true : false;
  // TODO: mobile without background
  // const isMobile = useMediaQuery('(max-width:800px)');

  return (
    <div className="bg-tali-black bg-tali-bg-glow-static bg-center bg-no-repeat">
      {disableCTA && <CTA {...(cta as CTAProps)} />}
      <div className="w-full h-[2px] bg-tali-onyx" />
      <div className={styles.linksContainer}>
        <div className={styles.wrapper}>
          <div className={styles.logo}>
            <Image src={SmallTaliIcon} alt="Tali-logo" />
          </div>
          <div className={clsx(styles.products, styles.footerColumn)}>
            <Text
              size="p2"
              type="dark"
              title="Product"
              textStyle={styles.title}
              textAlign="start"
            />
            <Link href="/features">
              <a>Product</a>
            </Link>
            <Link href='/pricing'>
              <a>Pricing</a>
            </Link>
            <Link href='/about-us'>
              <a>About Us</a>
            </Link>
            <Link href="/install">
              <a>Install</a>
            </Link>
            <Link href="/security-privacy">
              <a>Security and Privacy</a>
            </Link>
          </div>
          <div className={clsx(styles.support, styles.footerColumn)}>
            <Text
              size="p2"
              type="dark"
              title="Support"
              textStyle={styles.title}
              textAlign="start"
            />
            <Link href="/resources">
              <a>Resources</a>
            </Link>
            <Link href="/contact-us">
              <a>Contact Us</a>
            </Link>
            <Link href="/faq">
              <a>FAQ</a>
            </Link>
          </div>
          <div className={clsx(styles.legal, styles.footerColumn)}>
            <Text
              size="p2"
              type="dark"
              title="Legal"
              textStyle={styles.title}
              textAlign="start"
            />
            <Link href="/terms-of-use">
              <a>Terms of Use</a>
            </Link>
            <Link href="/privacy-policy">
              <a>Privacy Policy</a>
            </Link>
            <Link href="/cookie-policy">
              <a>Cookies Policy</a>
            </Link>
          </div>
          <div className={clsx(styles.icons, styles.footerColumn)}>
            <Text
              size="p2"
              type="dark"
              title="Follow Us"
              textStyle={styles.title}
              textAlign="start"
            />
            <div className={styles.iconsWrapper}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://ca.linkedin.com/company/tali-ai/"
              >
                <div className={styles.iconWrapper}>
                  <Image
                    src={LinkedinIcon}
                    alt="Connect on Linked In"
                    layout="fixed"
                  />
                </div>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/TaliAICompany"
              >
                <div className={styles.iconWrapper}>
                  <Image
                    src={Twitter}
                    alt="Follow us on Twitter"
                    layout="fixed"
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-[2px] bg-tali-onyx" />
      <div className={styles.footerFooter}>
        <div className={styles.copyrightContainer}>
          <div className={styles.copyrightText}>
            <Text
              type="dark"
              textAlign="start"
              size="copyright"
              title="Tali AI"
            />
            <Text
              type='dark'
              textAlign='start'
              size='copyright'
              title='Copyright © 2023'
            />
          </div>
        </div>
      </div>
    </div>
  );
}
