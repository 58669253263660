import clsx from 'clsx';

import Footer from '../components/footer/Footer';
import Navbar from '../components/navbar/Navbar';
import NotificationBanner from '../components/NotificationBanner';
import { CTAProps } from '../components/footer/CTA';
import styles from './Navigator.module.scss';

export enum StyleVariant {
  DEFAULT = 'default',
  WAVE = 'wave',
}

type NavigatorProps = {
  children: React.ReactNode;
  variant?: StyleVariant;
  cta?: CTAProps | boolean;
};

export default function Navigator(navigator: NavigatorProps) {
  const { children, variant = StyleVariant.DEFAULT, cta = true } = navigator;

  return (
    <div
      className={clsx({
        ['bg-tali-black bg-tali-bg-glow-static bg-top bg-no-repeat']:
          variant === StyleVariant.DEFAULT,
        [`${styles.waveBg} bg-tali-installation-bg bg-center bg-no-repeat bg-contain`]:
          variant === StyleVariant.WAVE,
      })}
    >
      <NotificationBanner/>
      <Navbar />
      {children}
      <Footer cta={cta} />
    </div>
  );
}
