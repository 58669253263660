import React, { useRef, useState } from 'react';
import { getRefValue, getTouchEventData, useStateRef } from './hooks';
import styles from './swiper.module.scss';
import clsx from 'clsx';

const MIN_SWIPE_REQUIRED = 40;

function Swiper(props: {
/* eslint-disable  @typescript-eslint/no-explicit-any */
  items: Array<any>;
  renderItem: (item: any) => JSX.Element;
  customStyle?:string
}) {
  const containerRef = useRef<HTMLUListElement>(null);
  const minOffsetXRef = useRef(0);
  const curentOffSetXRef = useRef(0);
  const startXRef = useRef(0);
  const containerWidthRef = useRef(0);

  const [isSwiping, setIsSwiping] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const [offSetX, setOffSetX, offSetXRef] = useStateRef(0);

  const onTouchMove = (e: TouchEvent | MouseEvent) => {
    const currentX = getTouchEventData(e).clientX;
    const startX = getRefValue(startXRef);
    const diff = startX - currentX;
    const currentOffSetX = getRefValue(curentOffSetXRef);
    let newOffsetX = currentOffSetX - diff;

    if (newOffsetX > 0) {
      newOffsetX = 0;
    }
    if (newOffsetX < getRefValue(minOffsetXRef)) {
      newOffsetX = getRefValue(minOffsetXRef);
    }

    setOffSetX(newOffsetX);
  };
  const onTouchend = () => {
    const containerWidth = getRefValue(containerWidthRef);
    const currentOffSetX = getRefValue(curentOffSetXRef);

    let newOffsetX = getRefValue(offSetXRef);
    const diff = currentOffSetX - newOffsetX;

    if (Math.abs(diff) > MIN_SWIPE_REQUIRED) {
      if (diff > 0) {
        newOffsetX = Math.floor(newOffsetX / containerWidth) * containerWidth;
      } else {
        newOffsetX = Math.ceil(newOffsetX / containerWidth) * containerWidth;
      }
    } else {
      newOffsetX = Math.round(newOffsetX / containerWidth) * containerWidth;
    }

    setIsSwiping(false);
    setOffSetX(newOffsetX);
    setCurrentIndex(Math.abs(newOffsetX / containerWidth));

    window.removeEventListener('touchmove', onTouchMove);
    window.removeEventListener('touchend', onTouchMove);
  };

  const onTouchStart = (
    e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>,
  ) => {
    setIsSwiping(true);
    const currentOffSetX = getRefValue(offSetXRef);
    curentOffSetXRef.current = currentOffSetX;
    startXRef.current = getTouchEventData(e).clientX;

    const containerEl = getRefValue(containerRef);
    containerWidthRef.current = containerEl.offsetWidth;
    minOffsetXRef.current = containerEl.offsetWidth - containerEl.scrollWidth;

    window.addEventListener('touchmove', onTouchMove);
    window.addEventListener('touchend', onTouchend);
  };

  const indicatorOnClick = (idx: number) => {
    const containerEl = getRefValue(containerRef);
    const containerWidth = containerEl.offsetWidth;

    setCurrentIndex(idx);
    setOffSetX(-(containerWidth * idx));
  };

  return (
    <div
      className={clsx({[styles.swiperContainer]:true},props.customStyle)}
      onMouseDown={onTouchStart}
      onTouchStart={onTouchStart}
      role="presentation"
    >
      <ul
        ref={containerRef}
        className={`${styles.swiperList} ${isSwiping ? styles.swipping : ''}`}
        style={{ transform: `translate3d(${offSetX}px, 0, 0)` }}
      >
        {props.items.map((item, index) => (
          <li className={styles.swiperItem} key={index.toString()}>
            {props.renderItem(item)}
          </li>
        ))}
      </ul>
      <ul className={styles.swiperIndicator}>
        {props.items.map((_item, index) => (
          <li
            key={index.toString()}
            role="presentation"
            onClick={() => {
              indicatorOnClick(index);
            }}
            className={`${styles.swipperIndicatorItem} ${
              index === Math.ceil(currentIndex) ? styles.activeIndicator : ''
            }`}
          />
        ))}
      </ul>
    </div>
  );
}
export default Swiper;
